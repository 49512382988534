import { AppEnv, AppRegion, Environment, RuntimeConfig } from "@src/runtime-config-types";
import { runtimeConfig as runtimeConfigComDev } from "@src/runtime-config-com-dev";
import { runtimeConfig as runtimeConfigComStg } from "@src/runtime-config-com-staging";
import { runtimeConfig as runtimeConfigComPrd } from "@src/runtime-config-com-production";
import { runtimeConfig as runtimeConfigEuDev } from "@src/runtime-config-eu-dev";
import { runtimeConfig as runtimeConfigEuStg } from "@src/runtime-config-eu-staging";
import { runtimeConfig as runtimeConfigEuPrd } from "@src/runtime-config-eu-production";
import { isNumberedEnv } from "@src/runtime-config-base";

// This is the environment variable that is replaced when deploying the static web app to any environment.
const RELEASE_ENVIRONMENT = "eu-dev";

const hostname = window.location.hostname;
const isLocalhost = hostname === "localhost";

/**
 * Gets the region of the app based on the hostname.
 * !: Only used for numbered environments!
 */
function getAppRegion(): AppRegion {
  if (hostname.includes(".com")) {
    return "com";
  }
  if (hostname.includes(".eu")) {
    return "eu";
  }
  throw new Error(`Unknown app region: ${hostname}`);
}

/**
 * Gets the environment of the app based on the hostname.
 * !: Only used for numbered environments!
 */
function getAppEnvironment(): Environment {
  if (hostname.includes(".dev.")) {
    return "dev";
  }
  if (hostname.includes(".staging.")) {
    return "staging";
  }
  return "production";
}

/**
 * Gets the app environment based on current region.
 * If it is used in localhost it will return the VITE_APP_ENV.
 * If it is used in a numbered environment it will return the app environment based on the hostname.
 * If it is used in a release environment it will return the RELEASE_ENVIRONMENT set on deployment.
 */
function getAppEnv(): AppEnv {
  if (isLocalhost) {
    return import.meta.env.VITE_APP_ENV as AppEnv;
  }
  if (isNumberedEnv) {
    const appRegion = getAppRegion();
    const appEnvironment = getAppEnvironment();
    if (appRegion === "com" && appEnvironment === "dev") {
      return "com-dev";
    }
    if (appRegion === "com" && appEnvironment === "staging") {
      return "com-staging";
    }
    if (appRegion === "com" && appEnvironment === "production") {
      return "com-production";
    }
    if (appRegion === "eu" && appEnvironment === "dev") {
      return "eu-dev";
    }
    if (appRegion === "eu" && appEnvironment === "staging") {
      return "eu-staging";
    }
    if (appRegion === "eu" && appEnvironment === "production") {
      return "eu-production";
    }
    throw new Error(`Unknown app region: ${appRegion} and app environment: ${appEnvironment}`);
  }
  return RELEASE_ENVIRONMENT as AppEnv;
}

/**
 * Gets the correct config file based on the app environment.
 */
function getRuntimeConfigFile(): RuntimeConfig {
  const appEnv = getAppEnv();

  if (!appEnv || appEnv.includes("INSERT")) {
    throw new Error("VITE_APP_ENV is missing locally or the environment is not set in the deployment environment.");
  }

  switch (appEnv) {
    case "com-dev":
      return runtimeConfigComDev;
    case "com-staging":
      return runtimeConfigComStg;
    case "com-production":
      return runtimeConfigComPrd;
    case "eu-dev":
      return runtimeConfigEuDev;
    case "eu-staging":
      return runtimeConfigEuStg;
    case "eu-production":
      return runtimeConfigEuPrd;
    default:
      throw new Error(`Unknown appEnv: ${appEnv}`);
  }
}

/**
 * Gets the finale runtime config based on the environment.
 * For localhost it does some extra modifications to remove unwanted keys,
 * for example Sentry.
 */
function getRuntimeConfig(): RuntimeConfig {
  const runtimeConfig = getRuntimeConfigFile();
  if (isLocalhost) {
    return {
      ...runtimeConfig,
      features: {
        ...runtimeConfig.features,
        // We don't want to log errors from local development to Sentry
        sentryDSNKey: "",
      },
      isNumberedEnv: false,
      numberedEnv: null,
    };
  }
  return runtimeConfig;
}

const runtimeConfig = getRuntimeConfig();

export { runtimeConfig };
